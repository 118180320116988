var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { visible: _vm.visible } },
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "机构名称",
              },
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["departName", {}],
                    expression: "['departName', {}]",
                  },
                ],
                staticStyle: { border: "0px" },
                attrs: { placeholder: "" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "上级部门",
              },
            },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%", border: "none", outline: "none" },
                attrs: {
                  disabled: "",
                  dropdownStyle: { maxHeight: "200px", overflow: "auto" },
                  treeData: _vm.treeData,
                  placeholder: "无",
                },
                model: {
                  value: _vm.model.parentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "parentId", $$v)
                  },
                  expression: "model.parentId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "机构编码",
              },
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["orgCode", {}],
                    expression: "['orgCode', {}]",
                  },
                ],
                staticStyle: { border: "0px" },
                attrs: { placeholder: "" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "机构类型",
              },
            },
            [
              _c(
                "a-radio-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: ["orgCategory", {}],
                      expression: "['orgCategory',{}]",
                    },
                  ],
                  attrs: { disabled: true, placeholder: "请选择机构类型" },
                },
                [
                  _c("a-radio", { attrs: { value: "1" } }, [
                    _vm._v("\n            公司\n          "),
                  ]),
                  _c("a-radio", { attrs: { value: "2" } }, [
                    _vm._v("\n            部门\n          "),
                  ]),
                  _c("a-radio", { attrs: { value: "3" } }, [
                    _vm._v("\n            岗位\n          "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "排序",
              },
            },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["departOrder", {}],
                    expression: "[ 'departOrder',{}]",
                  },
                ],
                staticStyle: { border: "0px" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "手机号",
              },
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["mobile", {}],
                    expression: "['mobile', {}]",
                  },
                ],
                staticStyle: { border: "0px" },
                attrs: { placeholder: "" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "地址",
              },
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["address", {}],
                    expression: "['address', {}]",
                  },
                ],
                staticStyle: { border: "0px" },
                attrs: { placeholder: "" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "备注",
              },
            },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["memo", {}],
                    expression: "['memo', {}]",
                  },
                ],
                staticStyle: { border: "0px" },
                attrs: { placeholder: "" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }